<template>
  <div class="container">
    <span>Ingresando...</span>
  </div>
</template>

<script>
import md5 from 'js-md5'

export default {
  props: {
    guid: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      socData: null,
      TipoPerfilEjecutivo:'',
      token: '',
    }
  },
  async mounted() {

    // return console.log(this.guid)
    await this.getTokenSisec();

    if ( !this.guid ) {
      // El multilogin fallo y termino su proceso de inicio
      localStorage.setItem('multiLoginLoading', false);
      return this.$router.push('/login').catch(() => {})
    }

    const isInmobiliaria = this.guid.includes('@') || false;

    isInmobiliaria ? this.ObtenCredenciales() : this.verificaUsuario();
  },
  methods: {
    async getTokenSisec() {
      let objRequestToken = {
        strApiKey: "a13768e0b29183037ac1f3acb68871bc",
        strMetodo: 'DameAuthToken',
      }
      await this.$axios.post('/', objRequestToken,
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
        .then( response => {
          if (!response.data.error) {
            this.token = response.data.access_token;
          } else {
            this.$vs.notify({
              title: 'Ocurrio un error al obtener token',
              text: response.data.error_description,
              color: 'danger',
              position: 'top-right',
            })
          }
        }).catch(function (error) {
          self.$vs.notify({
            title: 'Ocurrio un error de sistema',
            text: error,
            color: 'danger',
            position: 'top-right',
          })
        });
    },

    // Multilogin Inmobiliaria
    ObtenCredenciales() {

      const self = this
      const objRequestAutentica = {
        strApiKey: "a13768e0b29183037ac1f3acb68871bc",
        strMetodo: "DameDatosInmobiliario",
        objEjecutivo: { email: this.guid }
      }

      this.$axios
        .post("/", objRequestAutentica, {headers: {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}})
        .then(response => {
          const status = response.data.intEstatus

          if ( status ) {

            this.$vs.notify({
              title: "Ocurrio un error en DameDatosInmobiliario",
              text: response.data.strError,
              color: "danger",
              position: "top-right",
            })

          }

          localStorage.setItem('userInfo', JSON.stringify(response.data.objContenido))
          this.$store.commit("UPDATE_USER_INFO", response.data.objContenido)
          this.$router.push("/homeInmo").catch(() => {})
          // El multilogin termino su proceso de inicio
          localStorage.setItem('multiLoginLoading', false);
        })
        .catch(function (error) {
          self.$vs.notify({
            title: "Ocurrio un error de sistema Aute",
            text: error,
            color: "danger",
            position: "top-right"
          })

          self.$router.push('/login').catch(() => {})
          // El multilogin falló y termino su proceso de inicio
          localStorage.setItem('multiLoginLoading', false);
        })
    },

    // Multilogin SOC
    verificaUsuario() {

      const self = this
      const URL = `https://apivalidacionriesgos.sisec.mx/api/External/GetEmployee?id=${ this.guid }`
      const header = {
        // "authorization": "Bearer 16JXvQ9CD1eEoVESY3-4d-bElrAAgAfcB0UgYwAB-XyuNugkcBvgntr6n6VjEAFWenS9Exi_jlLv_RBfmf004TXytHP64dNEuSyhlXOBDtO56hW07txWd4evNWfukK5CO1ETRIOk8GWyjjS7daQ8eLHUz3XefoKEHKE0yIy76o8rZZYTGskAn1Zo3oKcPFzfs_fmVlO15cD_Kxx27-1qqDBsfAphhY7N599ly5ppo5L4XHaL8JAv6mTxXVYxrTZPQDmnLqQ9Nwre7Bd4URM5rR3rVMrAVkPzJ8ohQ9-pgvigB-Fra_SMi31c5T-QrSTB"
        "authorization": "Bearer " + this.token
      }

      this.$axios
        .get(URL,{headers : header})
        .then(response => {

          this.socData = response.data
          const data = response.data
          const userStatus = data.Status
          // console.log(this.socData)
          if ( userStatus !== 'Activo' ) {
            this.$router.push('/login').catch(() => {})
            // El multilogin fallo y termino su proceso de inicio
            localStorage.setItem('multiLoginLoading', false);
          }

          const ownerMail = data.OwnerMail
          const mail = data.Mail
          const isSupervisor = ownerMail === mail || false

          if ( isSupervisor ) {

            this.autenticaEjecutivoSOC(
              mail,
              this.guid,
              data.EmployeeName,
              data.CommercialReason,
              data.OwnerMail,
              data.OwnerId,
              data.OwnerName,
              data.BrokerId,
              true,
              data.Curp,
              data.Rfc,
              data.CurpCommercial,
              data.RfcCommercia,
              data.EmployeeRol,
              data.NoBroker
            )

          }
          else {

            this.autenticaEjecutivoSOC(
              data.OwnerMail,
              data.OwnerId,
              data.OwnerName,
              data.CommercialReason,
              data.OwnerMail,
              data.OwnerId,
              data.OwnerName,
              data.BrokerId,
              false,
              data.Curp,
              data.Rfc,
              data.CurpCommercial,
              data.RfcCommercia,
              data.EmployeeRol,
              data.NoBroker
            )

            this.autenticaEjecutivoSOC(
              mail,
              this.guid,
              data.EmployeeName,
              data.CommercialReason,
              data.OwnerMail,
              data.OwnerId,
              data.OwnerName,
              data.BrokerId,
              true,
              data.Curp,
              data.Rfc,
              data.CurpCommercial,
              data.RfcCommercia,
              data.EmployeeRol,
              data.NoBroker
            )

          }

        })
        .catch(error => {
          self.$vs.notify({
            title: 'Ocurrio un error de sistema',
            text: error,
            color: 'danger',
            position: 'top-right',
          })

          this.$router.push('/login').catch(() => {})
          // El multilogin falló y termino su proceso de inicio
          localStorage.setItem('multiLoginLoading', false);
        })
    },

    autenticaEjecutivoSOC ( email, pass, nombre, CommercialReason, supervisor, idSupervisor, nameSupervisor, idBroker, isSupervisor, curp, rfc, curpSupervisor, rfcSupervisor, EmployeeRol, NoBroker) {
      
      if(EmployeeRol=='Asesor'){
        this.TipoPerfilEjecutivo=5
      }else if(EmployeeRol=='CUENTA MAESTRA' || EmployeeRol=='Cuenta maestra 3.0' || EmployeeRol=='Cuenta Maestra Venta Cruzada' || EmployeeRol=='CuentaMaestraSOC'){
        this.TipoPerfilEjecutivo=1
      }else if(EmployeeRol=='Gerente'){
        this.TipoPerfilEjecutivo=2
      }else if(EmployeeRol=='Operador' || EmployeeRol=='Operador 3.0' || EmployeeRol=='Operador Venta Cruzada' || EmployeeRol=='OperadorSOC' ){
        this.TipoPerfilEjecutivo=3
      }else if(EmployeeRol=='OPERADORADMINISTRADOR'){
        this.TipoPerfilEjecutivo=4
      }else if(EmployeeRol=='Agente'){
        this.TipoPerfilEjecutivo=8
      }
      const self = this
      const objRequestAutentica = {
        strApiKey: 'a13768e0b29183037ac1f3acb68871bc',
        strMetodo: 'autenticaEjecutivoSOC',
        objEjecutivo: {
          EMail: email,
          Password: md5(pass),
          Guid: this.guid,
          Nombre: nombre,
          Curp: curp,
          Rfc: rfc, 
          RazonComercial: CommercialReason,
          TipoPerfilEjecutivo:  this.TipoPerfilEjecutivo,
          IdBrokerSisec: NoBroker,
          BrokerId: idBroker
        }
      }

      this.$axios.post('/',objRequestAutentica,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {

          const status = response.data.intEstatus

         // console.log(status)

          if ( status ) {
            return this.registraEjecutivo( email, pass, nombre, CommercialReason, supervisor, idSupervisor, nameSupervisor, idBroker, isSupervisor, curp, rfc, curpSupervisor, rfcSupervisor , EmployeeRol, NoBroker)
          }

          if ( !isSupervisor ) return

          this.login( email, pass, nombre, CommercialReason, curp, rfc , EmployeeRol, NoBroker) 

        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema7',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },

    registraEjecutivo( email, pass, nombre, CommercialReason, supervisor, idSupervisor, nameSupervisor, idBroker,isSupervisor, curp, rfc, curpSupervisor, rfcSupervisor, EmployeeRol, NoBroker ) {

      if(EmployeeRol=='Asesor'){
        this.TipoPerfilEjecutivo=5
      }else if(EmployeeRol=='CUENTA MAESTRA' || EmployeeRol=='Cuenta maestra 3.0' || EmployeeRol=='Cuenta Maestra Venta Cruzada' || EmployeeRol=='CuentaMaestraSOC'){
        this.TipoPerfilEjecutivo=1
      }else if(EmployeeRol=='Gerente'){
        this.TipoPerfilEjecutivo=2
      }else if(EmployeeRol=='Operador' || EmployeeRol=='Operador 3.0' || EmployeeRol=='Operador Venta Cruzada' || EmployeeRol=='OperadorSOC' ){
        this.TipoPerfilEjecutivo=3
      }else if(EmployeeRol=='OPERADORADMINISTRADOR'){
        this.TipoPerfilEjecutivo=4
      }


      const self = this
      const objRequestRegistraEjecutivo = {
        strApiKey: 'a13768e0b29183037ac1f3acb68871bc',
        strMetodo: "RegistraEjecutivo",
        objEjecutivo: {
          EMail: email,
          Nombre: nombre,
          Telefono:'1234567890',
          Password: md5(pass),
          TipoBroker: 1,
          Organizacion: 3,
          Activo: 1,
          BuroCredito: true,
          EMailSupervisor: !isSupervisor ? supervisor : '',
          SocGuid : !isSupervisor ? idSupervisor : this.guid,
          OwnerId: idSupervisor,
          OwnerName: nameSupervisor,
          OwnerMail: supervisor,
          BrokerId: idBroker,
          CommercialReason: CommercialReason,
          Curp: curp,
          Rfc: rfc,
          //OwnerCurp: !isSupervisor ? curpSupervisor : '',
          //OwnerRfc: !isSupervisor ? rfcSupervisor : '',
          CurpCommercial: isSupervisor ? curpSupervisor : '',
          RfcCommercia: isSupervisor ? rfcSupervisor : '',
          TipoPerfilEjecutivo: this.TipoPerfilEjecutivo,
          IdBrokerSisec: NoBroker
        }
      }

      this.$axios.post('/',objRequestRegistraEjecutivo,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {

          const status = response.data.intEstatus

          if ( status ) {
            return this.$vs.notify({
              title:'Ocurrio un error en RegistraEjecutivo',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }

          if ( !isSupervisor ) return

          this.login( email, pass, nombre, CommercialReason, curp, rfc , EmployeeRol, NoBroker) 

        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema8',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },

    login( email, pass, nombre, CommercialReason, curp, rfc , EmployeeRol, NoBroker) {

       if(EmployeeRol=='Asesor'){
        this.TipoPerfilEjecutivo=5
      }else if(EmployeeRol=='CUENTA MAESTRA' || EmployeeRol=='Cuenta maestra 3.0' || EmployeeRol=='Cuenta Maestra Venta Cruzada' || EmployeeRol=='CuentaMaestraSOC'){
        this.TipoPerfilEjecutivo=1
      }else if(EmployeeRol=='Gerente'){
        this.TipoPerfilEjecutivo=2
      }else if(EmployeeRol=='Operador' || EmployeeRol=='Operador 3.0' || EmployeeRol=='Operador Venta Cruzada' || EmployeeRol=='OperadorSOC' ){
        this.TipoPerfilEjecutivo=3
      }else if(EmployeeRol=='OPERADORADMINISTRADOR'){
        this.TipoPerfilEjecutivo=4
      }

      const self = this
      const objRequestAutentica = {
        strApiKey: 'a13768e0b29183037ac1f3acb68871bc',
        strMetodo: 'autenticaEjecutivoSOC',
        objEjecutivo: {
          EMail: email,
          Password: md5(pass),
          Guid: this.guid,
          Nombre:nombre,
          Curp: curp,
          Rfc:rfc,
          RazonComercial: CommercialReason, 
          TipoPerfilEjecutivo: this.TipoPerfilEjecutivo,
          IdBrokerSisec: NoBroker
        }
      }

     // console.log(objRequestAutentica)
    //  console.log(objRequestAutentica)

      this.$axios.post('/',objRequestAutentica,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {

          // return console.log(response)

          const status = response.data.intEstatus

          if ( status ) {

            return this.$vs.notify({
              title:'Ocurrio un error en autenticaEjecutivoSOC',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })

          }

          const sessionMultilogin = response.data.objContenido.SesionMultilogin
          const tipoEjecutivoV3 = response.data.objContenido.TipoEjecutivoV3
          const socGuid = response.data.objContenido.SocGuid

          // return console.log({ sessionMultilogin, tipoEjecutivoV3, socGuid })

          if( sessionMultilogin === false && tipoEjecutivoV3 === 'SOC' && ( socGuid ) ) {

            this.$cookie.delete('key')
            localStorage.removeItem('userInfo')
            localStorage.removeItem('socData')
            this.$router.push('/login').catch(() => {})
            // El multilogin falló y termino su proceso de inicio
            localStorage.setItem('multiLoginLoading', false);
          }
          else {

            this.$store.commit('UPDATE_USER_INFO',response.data.objContenido)
            this.$store.commit('UPDATE_SOC_DATA',this.socData)

            this.$router.push('/').catch(() => {})
            // El multilogin termino su proceso de inicio
            localStorage.setItem('multiLoginLoading', false);
          }

        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema Aute',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },

  },
};
</script>

<style scoped>
.container {
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
